import React, { useState, useEffect, useRef } from 'react';
import './App.css';

function App() {

  const [days, setDays] = useState(0);
  const [hours, setHours] = useState(0);
  const [minutes, setMinutes] = useState(0);
  const [seconds, setSeconds] = useState(0);

  const requestRef = useRef();
  const targetDate = new Date("December 31, 2020, 23:00:00").getTime();
  
  const animate = time => {
    const nyTime = new Date().toLocaleString("en-US", {timeZone: "America/New_York"});
    const now = new Date(nyTime).getTime();
    const ms = targetDate - now;

    const d = Math.floor(ms / (1000 * 60 * 60 * 24));
    const h = Math.floor((ms / (1000 * 60 * 60)) % 24);
    const m = Math.floor((ms / 1000 / 60) % 60);
    const s = Math.floor((ms / 1000) % 60);

    setDays(d);
    setHours(`${h}`.length < 2 ? `0${h}` : h);
    setMinutes(`${m}`.length < 2 ? `0${m}` : m);
    setSeconds(`${s}`.length < 2 ? `0${s}` : s);

    requestRef.current = requestAnimationFrame(animate);
  }
  
  useEffect(() => {
    requestRef.current = requestAnimationFrame(animate);
    return () => cancelAnimationFrame(requestRef.current);
  }, []);

  return (
    <div className="container">
      <div className="display one">
        <h1 className="days">{days}</h1>
        <h1 className="label">days</h1>
      </div>
      <div className="display two">
        <div className="row">
          <h1 className="time">{hours}</h1>
          <h1 className="label">h</h1>
        </div>
        <div className="row">
          <h1 className="time">{minutes}</h1>
          <h1 className="label">min</h1>
        </div>
        <div className="row">
          <h1 className="time">{seconds}</h1>
          <h1 className="label">sec</h1>
        </div>
      </div>
      <div className="display three">
        <h1 className="label">until</h1>
      </div>
    </div>
  );
}

export default App;
